<template>
  <table-view
    :filterable=false
    :filter-form="dataForm"
    ref="tableView"
    url="/company/listForPage"
    :downloadable="isAuthed('car_company_download')"
    download-url="/company/download/template"
    download-file-name="合作公司信息模板"
    :uploadable="isAuthed('car_company_import')"
    upload-title="批量上传合作公司信息"
    upload-url="/company/batch"
    :upload-columns="uploadColumns"
    :batch-removable="isAuthed('car_company_delete')"
    delete-url="/company/deleteById"
    :exportable="isAuthed('car_company_export')"
    export-url="/company/download"
    export-file-name="合作公司信息表"
    :addable="isAuthed('car_company_add')"
    :editable="isAuthed('car_company_update')"
    :auditable="isAuthed('car_company_approve')"
    :assignable="isAuthed('company_dispatcher_update')"
    :headers="headers"
    show-index
    selectable
    single
  >

    <el-form slot="search" @keyup.enter.native="query">
      <el-input v-model="dataForm.companyName" clearable placeholder="公司名称"></el-input>
      <el-button size="mini" @click="query" type="primary">查询</el-button>
    </el-form></table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'car-partner',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'companyName', label: '公司名称', width: 120 },
        { id: 2, prop: 'companyAddress', label: '公司地址', minWidth: 250 },
        { id: 3, prop: 'legalPerson', label: '公司法人', width: 100 },
        { id: 4, prop: 'legalPhone', label: '法人联系方式', width: 120 },
        { id: 6, prop: 'carNumber', label: '车辆数量', width: 100 },
        { id: 7, prop: 'userNumber', label: '人员数量', width: 100 },
        { id: 8, prop: 'dispatcherNickName', label: '二级调度', width: 100 }
      ],
      uploadColumns: [
        { id: 1, prop: 'companyName', label: '公司名称', width: 120 },
        { id: 2, prop: 'companyAddress', label: '公司地址', minWidth: 250 },
        { id: 3, prop: 'legalPerson', label: '公司法人', width: 100 },
        { id: 4, prop: 'legalPhone', label: '法人联系方式', width: 120 }
      ],
      dataForm: {
        companyName: ''
      }
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    }
  }
}
</script>
